<template>
  <div class="lottoBetYeekiPage" :key="componentKey">
    <div id="section-content" class="container container-fluid">
      <app-content1 />
       <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 179px) - 50px);" v-if="getYeekiInfo.status==4">
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
          <div class="lotto-title">
            <h4><i class="fas fa-exclamation-triangle"></i> {{ getYeekiInfo.group_name }} - {{ getYeekiInfo.title }}</h4>
          </div>
        </div>
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 xtarget col-lotto">
          <div class="text-center my-4">
            <span style="font-size:100px;line-height:1.2;"><i class="fas fa-spinner fa-spin text-danger"></i></span>
              <h4 class="text-danger" style="font-family:inherit"> ยกเลิกรอบเนื่องจากยอดการยิงเลขมีไม่ถึง 20 ลำดับ </h4>
            </div>
        </div>
      </div>
      <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 179px) - 50px);" v-else-if="endTime">
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
        <div class="lotto-title">
          <h4><i class="fas fa-exclamation-triangle"></i> {{ getYeekiInfo.group_name }}  - {{ getYeekiInfo.title }}</h4>
        </div>
      </div>
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 xtarget col-lotto">
          <div class="text-center my-4">
            <span style="font-size:100px;line-height:1.2;"><i class="fas fa-spinner fa-spin text-danger"></i></span>
              <h4 class="text-danger" style="font-family:inherit"> กำลังรอผลหวย</h4>
            </div>
          <div class="alert alert-danger text-center">กรุณา คลิกที่ปุ่ม <b>Refresh</b> เพื่อทำรายการใหม่อีกครั้ง</div>
        </div>
        <div class="bg-white p-2 rounded shadow-sm w-100 mb-5">
            <a href="" class="btn btn-dark btn-block text-white"><i class="fas fa-sync-alt"></i> Refresh</a>
        </div>
    </div>
      <div class="p-2 w-100 bg-light main-content align-self-stretch d-flex flex-column align-items-stretch"
        style="min-height: calc((100vh - 189px) - 50px)"  v-else>
        <app-content2 v-on:setEndTime="endTime = $event" />
        <div class="row-menu-grid">
          <app-content3 @openPrice="togglePrice($event)" @openSet="toggleSet($event)" :numberSets="setNumber"/>
          <app-content4/>
        </div>
      </div>
    </div>
    <app-confirm-model />
    <transition name="slide-fade">
      <app-price-modal @exitPrice="toggleExit($event)" @closePrice="togglePrice($event)" @openSend="toggleSend($event)" v-if="showModal" :limits="getYeekiInfo.price_limit ? getYeekiInfo.price_limit : []" :sumNumber="getYeekiInfo.limitNumber  ? getYeekiInfo.limitNumber : []" />
    </transition>
    <transition name="slide-fade">
      <app-send-modal
        :data="getYeekiInfo"
        @closeSend="toggleSend($event)"
        @openPrint="togglePrint($event)"
        v-if="showSend"
      />
    </transition>
    <transition name="slide-fade">
      <app-print-modal
        @closePrint="togglePrint($event)"
        v-if="showPrint"
        :data="(getYeekiInfo && initOk) ? getYeekiInfo : {}"
      />
    </transition>
    <transition name="slide-fade">
      <app-setnumber-modal v-if="showSet" @closeSet="toggleSet($event)" @sendSet="setNumber = $event"/>
    </transition>
    <app-clearing-modal @closePrice="togglePrice($event)"/>
  </div>
</template>
<script>
import ConfirmBetModal from '@/components/modals/ConfirmBetModal'
import content1 from '@/pages/lottoBetYeekiPage/content/content1'
import content2 from '@/pages/lottoBetYeekiPage/content/content2'
import content3 from '@/pages/lottoBetYeekiPage/content/content3'
import content4 from '@/pages/lottoBetYeekiPage/content/content4'
import pricemodal from '@/components/modals/PriceModal'
import sendmodal from '@/components/modals/SendpoyModal'
import printmodal from '@/components/modals/PrintpoyModal'
import clearingModal from '@/components/modals/ClearingModal'
import setnumberModal from '@/components/modals/SetNumberModal'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    'app-content1': content1,
    'app-content2': content2,
    'app-content3': content3,
    'app-content4': content4,
    'app-confirm-model': ConfirmBetModal,
    'app-price-modal': pricemodal,
    'app-send-modal': sendmodal,
    'app-print-modal': printmodal,
    'app-clearing-modal': clearingModal,
    'app-setnumber-modal': setnumberModal
  },
  data () {
    return {
      showModal: false,
      showSend: false,
      showPrint: false,
      showSet: false,
      componentKey: 0,
      setNumber: [],
      limits: [],
      sumNumber: [],
      endTime: false,
      initOk: false
    }
  },
  async beforeMount () {
    this.setLoading(true)
    await this.getYeekiInfomationByRoundId(this.$route.params.id)
    this.setLoading(false)
  },
  watch: {
    setNumber: function (val) {
      this.setNumber = val
      this.showSet = false
    },
    setEndTime: function (val) {
      this.endTime = val
    }
  },
  computed: {
    ...mapGetters(['getSubCatYeekiAviliableList', 'getYeekiInfo'])
  },
  methods: {
    ...mapActions(['getYeekiInfomationByRoundId', 'setLoading']),
    toggleExit (toggle) {
      this.showModal = toggle
    },
    togglePrice (toggle) {
      if (toggle) {
        this.showModal = toggle
      } else {
        this.showModal = toggle
        this.componentKey += 1
      }
    },
    toggleSend (toggle) {
      this.showSend = toggle
    },
    togglePrint (toggle) {
      if (toggle) {
        this.showModal = false
        this.showSend = false
      } else {
        this.componentKey += 1
      }
      this.showPrint = toggle
    },
    toggleSet (toggle) {
      this.showSet = toggle
    }
  }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
