<template>
  <b
    ><b>
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 mt-1 flex-column mb-5 d-none" id="numpage_2">
        <h3 class="text-center"><i class="sn-icon sn-icon--graph2"></i> ผลรวมยี่กี</h3>
        <h3 class="text-center" id="total_ying"></h3>
        <div class="form-shot-number">
          <input
            placeholder="กรอกตัวเลข 5 หลัก"
            maxlength="5"
            type="text"
            readonly="readonly"
            class="form-control form-control-lg text-center show-input-number"
          />
          <div class="key-pad box__keyboard yeekee__number" style="display: none">
            <div class="row p-2 group">
              <div class="col-3">
                <button class="btn btn-outline-primary btn-block" data-id="1">1</button>
              </div>
              <div class="col-3">
                <button class="btn btn-outline-primary btn-block" data-id="2">2</button>
              </div>
              <div class="col-3">
                <button class="btn btn-outline-primary btn-block" data-id="3">3</button>
              </div>
              <div class="col-3">
                <button class="btn btn-danger btn-primary delete__number" data-id="delete">
                  <i class="fas fa-backspace"></i>
                </button>
              </div>
              <div class="col-3">
                <button class="btn btn-outline-primary btn-block" data-id="4">4</button>
              </div>
              <div class="col-3">
                <button class="btn btn-outline-primary btn-block" data-id="5">5</button>
              </div>
              <div class="col-3">
                <button class="btn btn-outline-primary btn-block" data-id="6">6</button>
              </div>
              <div class="col-3">
                <button class="btn btn-secondary btn-block random__number">
                  <span>สุ่มเลข</span>
                </button>
              </div>
              <div class="col-3">
                <button class="btn btn-outline-primary btn-block" data-id="7">7</button>
              </div>
              <div class="col-3">
                <button class="btn btn-outline-primary btn-block" data-id="8">8</button>
              </div>
              <div class="col-3">
                <button class="btn btn-outline-primary btn-block" data-id="9">9</button>
              </div>
              <div class="col-3">
                <button class="btn btn-success btn-block submit-number yeekee__submit">เพิ่มเลข</button>
              </div>
              <div class="col-3">
                <button class="btn btn-blank" disabled=""></button>
              </div>
              <div class="col-3">
                <button class="btn btn-outline-primary btn-block" data-id="0">0</button>
              </div>
              <div class="col-3">
                <button class="btn btn-blank" disabled=""></button>
              </div>
              <div class="col-3"></div>
            </div>
          </div>
        </div>
        <hr class="mt-1 mb-2" />
        <div class="d-flex flex-column yeekee__lists-number" style="display: none">
          <h5 class="text-center text-success"><i class="fas fa-th-list"></i> รายชื่อผู้ทายเลข</h5>
          <span id="yresult"></span>
          <div class="flex-row justify-content-between align-items-stretch mb-1" id="list_ying-1" style="display: none">
            <div class="bg-success text-white text-center rounded p-2 w-25 d-flex flex-column justify-content-around">
              <span class="badge badge-pill badge-light text-success">อันดับที่ <span data-id="ly_ranked"></span></span>
              <h3 class="mb-0"><span data-id="ly_ying"></span></h3>
            </div>
            <div
              class="border border-success bg-light rounded p-2 ml-1 flex-fill d-flex flex-column justify-content-around"
            >
              <div>
                <span class="badge badge-pill badge-light text-success"
                  ><i class="fas fa-user-circle"></i> ผู้ส่งเลข</span
                >
                <span data-id="ly_send"></span>
              </div>
              <hr class="my-1" />
              <div>
                <span class="badge badge-pill badge-light text-success"
                  ><i class="fas fa-calendar-check"></i> เมื่อ</span
                >
                <span data-id="ly_date"></span>
              </div>
            </div>
          </div>
          <div class="flex-row justify-content-between align-items-stretch mb-1" id="list_ying-0" style="display: none">
            <div class="bg-secondary text-white text-center rounded p-2 w-25 d-flex flex-column justify-content-around">
              <span class="badge badge-pill badge-light">อันดับที่ <span data-id="ly_ranked"></span></span>
              <h3 class="mb-0"><span data-id="ly_ying"></span></h3>
            </div>
            <div
              class="border border-secondary bg-light rounded p-2 ml-1 flex-fill d-flex flex-column justify-content-around"
            >
              <div>
                <span class="badge badge-pill badge-light"><i class="fas fa-user-circle"></i> ผู้ส่งเลข</span>
                <span data-id="ly_send"></span>
              </div>
              <hr class="my-1" />
              <div>
                <span class="badge badge-pill badge-light"><i class="fas fa-calendar-check"></i> เมื่อ</span>
                <span data-id="ly_date"></span>
              </div>
            </div>
          </div>
        </div>
      </div> </b
  ></b>
</template>

<script>
export default {}
</script>

<style>
</style>
