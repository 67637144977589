<template>
  <div
    class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-0 pb-0"
  >
    <div
      class="d-flex justify-content-between align-items-center mb-0 flex-fill lotto-title"
    >
    <h4 class="tanghuay-h4">
        {{ getYeekiInfo.group_name }}
        <span v-if="getYeekiInfo.group_name!=''" class="badge badge-pill badge-primary font-weight-light">
          <small> {{ getYeekiInfo.title }}</small>
        </span>
      </h4>
    <div class="tanghuay-time">
        <i class="sn-icon sn-icon--daily2"></i>
        <span
          class="countdown"
          id="offset"
          data-finaldate="1610871600000"
          v-html="getYeekiInfo.date.date_close != null ? calTime(getYeekiInfo.date.date_close) : ''"
        >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
export default {
  async created () {
    setInterval(() => {
      this.getNow()
    }, 1000)
    await this.getYeekiRound(this.$route.params.catalog)
    this.dataInfo = this.getSubCatYeekiAviliableList.find(
      (e) => parseInt(e.yeekiRoundID) === parseInt(this.$route.params.id)
    )
    this.setTitleBet(this.dataInfo.yeekiRoundNum)
  },
  methods: {
    ...mapActions(['getYeekiRound', 'setTitleBet']),
    calTime (value) {
      if (value === null && value === '') {
        return '00<small>:</small>00<small>:</small>XX'
      }
      const final = moment(value)
      const now = this.currentDate ? this.currentDate : ''
      const timeleft = final - now
      if (timeleft > 0) {
        const hours = Math.floor(
          (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        let seconds = Math.floor((timeleft % (1000 * 60)) / 1000)
        if (seconds < 10) {
          seconds = '0' + seconds
        }
        return `${hours}<small>:</small>${minutes}<small>:</small>${seconds}`
      } else {
        if (value != null && value !== '') {
          this.endTime = true
          this.testData = now
          this.$emit('setEndTime', true)
          this.$router.push('/member/lottery/yeeki-fire/' + this.$route.params.catalog + '/' + this.$route.params.id)
        }
        return '00<small>:</small>00<small>:</small>00'
      }
    },
    getNow () {
      this.currentDate = moment()
    }
  },
  data: () => {
    return {
      dataInfo: {},
      currentDate: '',
      testData: ''
    }
  },
  computed: {
    ...mapGetters(['getSubCatYeekiAviliableList', 'getYeekiInfo'])
  }
}
</script>

<style>
</style>
