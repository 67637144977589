<template>
  <div class="content1">
    <div class="bar-back d-flex justify-content-between align-items-center">
      <div id="top"></div>
      <router-link :to="`/member/lottery/yeeki/${catalogId}`" class="mr-auto" id="yingying"
        ><i class="fas fa-chevron-left"></i> ย้อนกลับ</router-link
      >
      <a v-b-modal.rule-yeekee href="#" class="btn btn-outline-secondary btn-sm mr-1 text-dark" >กติกา</a>
      <!-- <a href="#" class="btn btn-outline-secondary btn-sm mr-1 text-dark" data-toggle="modal" data-target="#rule-yeekee"
        >กติกา</a
      > -->
      <button
          class="tn btn-dark btn-sm btn-numpage active"
          @click="pushNumber()"
          ><i class="fas fa-calculator"></i> ยิงเลข</button
        >
      <a
        href="#"
        class="btn btn-dark btn-sm btn-numpage d-none active"
        data-id="numpage_1"
        ><i class="fas fa-calculator"></i> แทงเลข</a
      >
    </div>
    <app-rule-yeekee-modal />
  </div>
</template>

<script>
import ruleYeekeeModal from '@/components/modals/RuleYeekeeModal'
export default {
  components: {
    'app-rule-yeekee-modal': ruleYeekeeModal
  },
  created () {
    this.roundID = this.$route.params.id
    this.catalogId = this.$route.params.catalog
  },
  methods: {
    pushNumber () {
      this.$router.replace(`/member/lottery/yeeki-fire/${this.catalogId}/${this.roundID}`)
    }
  },
  data () {
    return {
      roundID: '',
      catalogId: ''
    }
  }
}
</script>

<style>
</style>
