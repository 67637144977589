<template>
  <div class="RuleModal">
    <b-modal id="rule-yeekee" hide-footer>
      <template #modal-title>
        กติกา
      </template>
      <div class="d-block text-center">
        <div style="width: 100%; overflow-y: scroll;height: 80vh;" v-if="IMAGE_PATH">
          <img :src="IMAGE_PATH + 'rule_'+lot_type_id+'.png'" style="width: 100%">
        </div>
        <div v-html="advice" style="width: 100%; overflow-y: scroll;height: 80vh;" v-else/>
      </div>
      <b-button  class="btn btn btn-danger mt-3" block @click="$bvModal.hide('rule-yeekee')">ฉันเข้าใจและยอมรับ</b-button>
    </b-modal>
    <!-- <div
      class="modal fade"
      id="rule-yeekee"
      tabindex="-1"
      role="dialog"
      aria-labelledby="rule-yeekee"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" style="border-radius: 10px">
          <div class="modal-header bg-dark-blue text-white">
            <h5 class="modal-title">กฏและกติกา</h5>
            <button type="button" class="text-white close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-html="advice" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-dark-blue" data-dismiss="modal">ฉันเข้าใจและยอมรับ</button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      advice: state => state.yeekeeModule.yeekiInfo.advice,
      lot_type_id: state => state.yeekeeModule.yeekiInfo.lot_type_id
    })
  },
  data () {
    return {
      IMAGE_PATH: process.env.VUE_APP_LOTTO_IMAGE_PATH
    }
  }
}
</script>

<style>
.bg-dark-blue {
  background: rgba(31, 49, 84, 1);
}
.btn-dark-blue {
  background: rgba(31, 49, 84, 1);
  color: white;
}
</style>
